'use client'

import { LoadingContext } from "./loading-context";
import { useState } from "react";

type LoadingProviderProps = {
    children: React.ReactNode;
};

export function LoadingProvider({ children }: LoadingProviderProps): React.JSX.Element {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('loading');

    return (
        <LoadingContext.Provider value={{ loading, setLoading, message, setMessage }}>
            {children}
        </LoadingContext.Provider>
    );
}
