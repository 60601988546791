'use client'
import { createContext } from "react";

type LoadingContextState = {
    loading: boolean;
    setLoading: (loading: boolean) => void;
    message: string;
    setMessage: (message: string) => void;
}

export const LoadingContext = createContext<LoadingContextState>({
    loading: false,
    setLoading: () => undefined,
    message: '',
    setMessage: () => undefined,
});
